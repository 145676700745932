import React from "react"
import Layout from "../components/layout"
import {
  HeaderSecondary,
  ContactSection,
  DecorationSection,
} from "../components/utility"

import { useStaticQuery, graphql } from "gatsby"

export default function Contact() {
  const data = useStaticQuery(graphql`
    query {
      contact: file(relativePath: { eq: "decorative/operahouse.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const Reach = [
    data.contact.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.38),
      rgba(0, 0, 0, 0.38))`,
  ].reverse()

  return (
    <Layout>
      <HeaderSecondary
        fluid={Reach}
        title="Find out how"
        smalltitle="we can help you"
        captions=""
      />
      <DecorationSection />
      <ContactSection />
    </Layout>
  )
}
